<template>
  <div>
    <v-jsoneditor
      v-model="json"
      :options="jsonEditorOptions"
      :plus="false"
      :height="'260px'"
      @input="jsonIsValid"
      @error="jsonNotValid"
    />
    <small v-if="isNotValid" class="text-danger">JSON format is invalid</small>
    <b-input style="visibility: hidden" ref="json-error" />
  </div>
</template>

<script>
import VJsoneditor from 'v-jsoneditor/src/index';

export default {
  name: 'JsonEditor',
  props: ['value'],
  components: { VJsoneditor },
  data() {
    return {
      json: {},
      isNotValid: false,
      jsonEditorOptions: {
        mode: 'code',
        enableTransform: false,
        enableSort: false,
        history: false,
      },
    };
  },
  mounted() {
    this.json = this.value;
  },
  watch: {
    value(newVal) {
      this.json = newVal;
    },
  },
  methods: {
    jsonIsValid() {
      let input = this.$refs['json-error'];
      input.setCustomValidity('');
      this.isNotValid = false;
      this.$emit('input', this.json);
      this.$emit('change', this.json);
    },
    jsonNotValid() {
      let input = this.$refs['json-error'];
      input.setCustomValidity('JSON format is invalid');
      this.isNotValid = true;
    },
  },
};
</script>

<style scoped></style>
